import { useFormContext, Controller } from "react-hook-form";
import { Button, Col, Row, Typography, Select } from "antd";
import { IModelCompanies } from "../../@types";
import {
  InputNumber,
  InputSelect,
  InputSelectDynamic,
  InputText,
  clustersService,
  keyAccountManagersService,
} from "../../../../shared";

import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux";

const { Title } = Typography;

export function CompaniesForm() {
  const { control } = useFormContext<IModelCompanies>();
  const user = useSelector(selectUser);
  return (
    <>
      <Row justify="start">
        <Col
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          span={24}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nombre - Razón social:
          </Title>
          <InputText
            disabled
            placeHolder=""
            name="legal_name"
            control={control}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          span={24}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Nit:
          </Title>
          <InputText disabled placeHolder="" name="dni" control={control} />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Cluster:
          </Title>
          <InputSelectDynamic
            name="cluster_id"
            control={control}
            serviceName={clustersService}
            labelName="name"
            valName="id"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
              Key account manger:
          </Title>
          <InputSelectDynamic
            name="key_account_manager_id"
            control={control}
            serviceName={keyAccountManagersService}
            labelName="first_name"
            labelName2="last_name"
            valName="id"
          />
        </Col>
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {user?.role === 'super_admin' && (
            <>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Es afiliado:
          </Title>
          <Controller
            name="is_affiliate"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                style={{ width: '100%' }}
                placeholder="Es afiliado"
                onChange={(value) => field.onChange(String(value))}
              >
                <Select.Option value="true">Si</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            )}
          />
          </>
          )}
        </Col>
      </Row>
    </>
  );
}
