import feathersClient,{ feathersStorage } from '../../clients/feathers.clients'

export const login = async (email: string, password: string) => {
    return await feathersClient
        .authenticate({
            strategy: 'local',
            email,
            password,
        })
        
        .then((res) => {return res})
        .catch((err) => {
            throw err
        })
}

export const retryLogin = async () => {
    return await feathersClient
        .authenticate({
            strategy: 'jwt',
            accessToken: feathersStorage.getItem('jwt'),
        })
}


export const Logout = async () => {
    try {
        const res = await feathersClient.logout();
        // Clean up storage before reload
        feathersStorage.removeItem('jwt');
        feathersStorage.removeItem('user');
        window.location.href = '/login'; // More controlled than reload()
        return res;
    } catch (err: any) {
        // Even if logout fails, we should clean up local storage
        feathersStorage.removeItem('jwt');
        feathersStorage.removeItem('user');
        console.error('Logout error:', err);
        throw err;
    }
}
